<template>
    <div class="publish-main" style="padding-bottom: 68px;">
        <div class="fix-head">
            <!-- <div class="help">
              <i class="el-icon-info"></i>
              <span>已选择类目：</span>
              <span class="active">{{chooseName}} </span>
              <router-link class="active" to="/product/add">切换类目</router-link>
            </div> -->
            <el-button-group style="padding-bottom: 16px;">
                <el-button :type="active === 0 ? 'primary' : ''" @click="toAnchor(0)">基本信息</el-button>
                <el-button :type="active === 1 ? 'primary' : ''" @click="toAnchor(1)">销售信息</el-button>
                <el-button :type="active === 2 ? 'primary' : ''" @click="toAnchor(2)">商品首图</el-button>
                <el-button :type="active === 3 ? 'primary' : ''" @click="toAnchor(3)">图文详情</el-button>
                <el-button :type="active === 4 ? 'primary' : ''" @click="toAnchor(4)">售后服务</el-button>
            </el-button-group>
        </div>
        <div class="item" id="basic">
            <div class="head">基本信息</div>
            <div class="main basic">
                <div class="basic-item flex align-center">
                    <span>商品标题：</span>
                    <el-input v-model="title" style="width: 468px;" placeholder="请输入商品标题"></el-input>
                </div>
                <div class="basic-item flex">
                    <span>商品简介：</span>
                    <el-input v-model="desc" type="textarea" style="width: 468px;" placeholder="请输入商品简介，最多300字"
                              :maxlength="300"></el-input>
                </div>
                <div class="basic-item flex align-center">
                    <span>关联品牌：</span>
                    <el-select v-model="brandId" style="width: 468px;" placeholder="请选择品牌">
                        <el-option :label="item.brandName" :value="item.id" v-for="item in brandOptions"
                                   :key="item.id"></el-option>
                    </el-select>
                </div>
                <div class="basic-item flex align-center">
                    <span>关联类目：</span>
                    <el-cascader :options="categoryOptions" v-model="categoryId" placeholder="请选择类目"></el-cascader>
                </div>
                <div class="basic-item flex">
                    <div class="flex-center special">
                        <span>市场价：</span>
                        <el-input v-model="marketPrice" style="width: 190px;" placeholder="请输入"></el-input>
                    </div>
                    <div class="flex-center special">
                        <span>销售原价：</span>
                        <el-input v-model="salePrice" style="width: 190px;" readonly placeholder="取sku最低价"></el-input>
                    </div>
                    <div class="flex-center special">
                        <span>运费金额：</span>
                        <el-input v-model="transportFee" style="width: 190px;" readonly></el-input>
                    </div>
                </div>
                <div class="basic-item flex align-center">
                    <span>排序：</span>
                    <el-input type="number" v-model="sort" style="width: 128px;" placeholder="请输入排序"></el-input>
                </div>
            </div>
        </div>
        <div class="item" id="sku">
            <div class="head">销售信息</div>
            <div class="main sku">
                <div class="add">
                    <el-button class="tb-button" type="primary" @click="addSpec" :disabled="specList.length > 1">
                        <i class="tb-icon-plus"/>添加规格
                    </el-button>
                </div>
                <div style="padding-bottom: 32px;">
                    <span class="tips">为您的商品添加不同的规格以及不同的属性，如：尺码-S/M/L，颜色-红色/黄色等</span>
                    <span class="see-demo" @click="toDemo">查看示例</span>
                </div>
                <div v-for="(item, index) in specList" :key="index">
                    <div class="sku-name flex">
                        <span>规格名称{{index + 1}}：</span>
                        <el-input v-model="item.specname" style="width: 224px;" placeholder="建议1~2字"></el-input>
                    </div>
                    <el-table
                            :data="item.list"
                            class="tb-table sku-table"
                    >
                        <el-table-column label="属性名称">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.name" style="width: 270px;"
                                          :disabled="item.list[scope.$index].checked"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否勾选">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.checked" @change="changeCheck(scope.row)"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column label="属性图" v-if="0 === index">
                            <template slot-scope="scope">
                                <el-image :src="scope.row.url" style="width: 80px; height: 80px;" fit="contain"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span class="edit" @click="deleteSpec(index, scope.$index)">删除</span>
                                <span v-if="0 === index" class="edit" style="margin-left: 17px;" @click="editImg(index, scope.row)">设置属性图</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="flex">
                        <div class="add-item" @click="addAttributes(index)">
                            <i class="icon el-icon-circle-plus-outline"></i>
                            <span>添加属性</span>
                        </div>
                        <div class="add-item" @click="deleteGuige(index)" v-if="index > 0">
                            <i class="icon tb-icon-delete"></i>
                            <span>删除规格</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main">
                <div class="flex-between">
                    <div class="show-spec">宝贝销售规格</div>
                    <div class="felx-center">
                        价格：
                        <el-input
                                v-model="batchPrice"
                                placeholder="输入批量价格"
                                type="number"
                                style="width: 126px"
                        />
                        库存：
                        <el-input
                                v-model="batchStock"
                                placeholder="输入批量库存"
                                type="number"
                                style="width: 126px; margin-right: 12px;"
                        />
                        <el-button type="primary" @click="batchOperate" :disabled="!batchPrice && !batchStock">同步批量操作
                        </el-button>
                    </div>
                </div>
                <el-table
                        :data="skulist"
                        class="tb-table sku-table"
                >
                    <el-table-column prop="attribute_name_one" label="属性名称一"/>
                    <el-table-column prop="attribute_name_two" label="属性名称二"/>
                    <el-table-column label="价格">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.price" style="width: 100px;"
                                      @change="computedSalePrice"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="库存">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.stock" style="width: 100px;"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <span class="edit" @click="deleteSku(scope.row, scope.$index)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="item" id="textimg">
            <div class="head">图文详情</div>
            <div class="main textimg flex">
                <div class="textimg-left flex">
                    <span class="label">推荐主图</span>
                    <div class="flex upload-content">
                        <div class="upload logoupload" v-for="(item, index) in upLoadList" :key="item.id"
                             @click="logoClick(index)">
                            <div class="first flex-center" v-if="index === 0 && !item.url">商品首图</div>
                            <div class="logo" v-if="item.url">
                                <img :src="item.url" alt="logo" width="100%">
                                <div class="upload-operate flex-center">
                                    <div class="upload-operate-text">
                                        <Upload :id="item.id" src="product" @success="onLogoUpload">
                                            <span class="flex-center">更换</span>
                                        </Upload>
                                    </div>
                                    <el-divider direction="vertical"></el-divider>
                                    <div class="upload-operate-text flex-center cursor" @click="deleteImg(index)">删除
                                    </div>
                                </div>
                            </div>
                            <Upload :id="item.id" src="product" @success="onLogoUpload" v-else>
                                <div class="upload-box flex-center">
                                    <i class="el-icon-plus"></i>
                                    <span class="text">点击上传</span>
                                </div>
                            </Upload>
                        </div>
                    </div>
                    <span class="tips" style="padding-bottom: 32px;">建议主图尺寸为700x700像素。，且大小 &lt;=1MB，支持jpg、png格式，首图发商品白底图可增加曝光机会</span>
                    <span class="label">推荐视频（选填）</span>
                    <div class="upload video">
                        <div class="logo flex-center" v-if="video.src">
                            <img src="../../assets/icon/play.png" alt="logo" width="36px" @click="videoPlay = true">
                            <div class="upload-operate flex-center">
                                <div class="upload-operate-text">
                                    <Upload id="video" src="video" @success="onVideoUpload">
                                        <span class=" flex-center">更换</span>
                                    </Upload>
                                </div>
                                <el-divider direction="vertical"></el-divider>
                                <div class="upload-operate-text flex-center cursor" @click="deleteVedio">删除</div>
                            </div>
                        </div>
                        <Upload id="video" src="video" @success="onVideoUpload" v-else>
                            <div class="upload-box flex-center">
                                <i class="el-icon-plus"></i>
                                <span class="text">点击上传</span>
                            </div>
                        </Upload>
                    </div>
                    <span class="tips">仅支持 MP4 格式，视频尺寸建议1:1，视频长度 &lt;=15秒，大小10MB以内 (有视频将默认自动播放）</span>
                </div>
                <div class="textimg-right">
                    <h3 class="title">商品详情模版示例</h3>
                    <img src="https://ddimage.topboom0912.com/admin_static_img/demo/product.png" alt="demo" width="298px">
                </div>
            </div>
        </div>
        <div class="item" id="textimginfo">
            <div class="head">图文详情</div>
            <div class="main show">
                <div class="phone">
                    <div class="phone-head">商品详情</div>
                    <div class="phone-content flex">
                        <div class="phone-content-box" v-for="(item, index) in contentList" :key="index"
                             @mouseenter="item.show = true" @mouseleave="item.show = false">
                            <div class="phone-delete-btn" @click="deleteTextImg(index)" v-show="item.show">删除</div>
                            <span class="phone-text" v-if="item.content">{{item.content}}</span>
                            <img class="phone-img" v-if="item.media" :src="item.media.url" alt="detail"/>
                        </div>
                    </div>
                    <div class="phone-bottom flex">
                        <div class="bottom-btn flex-center">
                            <Upload id="bottom" src="product" @success="onPhoneImgUpload">
                                <div class="bottom-btn flex-center">
                                    <img src="../../assets/icon/product_img.png" width="20px" alt="productImg">
                                    <span>图片</span>
                                </div>
                            </Upload>
                        </div>
                        <div class="bottom-btn flex-center" @click="onPhoneAddText">
                            <img src="../../assets/icon/product_text.png" width="20px" alt="productText">
                            <span>文字</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item" id="serve">
            <div class="head">售后服务</div>
            <div class="main serve">
                <!-- <div class="serve-item">
                  <div>
                      <span class="label">分销比例：</span>
                      <div style="width: 200px; display: flex; align-items: center;">
                        <el-input v-model="returnRate" placeholder="请输入分销比例"></el-input>
                        <span style="margin-left: 8px;">%</span>
                      </div>
                  </div>
                </div>
                <div class="serve-item">
                    <span class="label">是否参与会员折扣：</span>
                    <el-radio-group v-model="viplevel">
                        <el-radio :label="1">V1以上</el-radio>
                        <el-radio :label="2">V2以上</el-radio>
                        <el-radio :label="3">V3以上</el-radio>
                        <el-radio :label="0">无</el-radio>
                    </el-radio-group>
                </div> -->
                <div class="serve-item">
                    <span class="label">营业状态：</span>
                    <el-radio-group v-model="status">
                        <el-radio :label="1">上架</el-radio>
                        <el-radio :label="0">下架</el-radio>
                    </el-radio-group>
                </div>
                <div class="serve-item">
                    <div class="time flex-center">
                        <span>上架时间：</span>
                        <el-date-picker
                                v-model="startTime"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间"
                        />
                    </div>
                    <div class="time flex-center">
                        <span>下架时间：</span>
                        <el-date-picker
                                v-model="endTime"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="fix-confirm">
            <el-button class="tb-button" @click="cancel">取消</el-button>
            <el-button class="tb-button" type="primary" @click="submit">发布</el-button>
        </div>
        <el-dialog
                title="播放视频"
                :visible.sync="videoPlay"
                width="400px">
            <div class="flex-center">
                <video :src="video.src" autoplay controls width="350px" height="250px"
                       v-if="video.src && videoPlay"></video>
            </div>
        </el-dialog>
        <el-dialog
                width="400px"
                title="添加品牌描述"
                :visible.sync="descVisible"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @close="descClose">
            <el-input v-model="productdesc" type="textarea" placeholder="请输入商品描述"
                      :autosize="{ minRows: 6, maxRows: 8}"></el-input>
            <span slot="footer" class="dialog-footer">
            <el-button @click="descVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmDesc">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog
            width="400px"
            title="设置属性图片"
            :visible.sync="isSpecImgDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="closeSpecImg"
            custom-class="spec-dialog"
            >
            <span>属性图片</span>
            <div class="spec" style="margin-top: 8px; margin-bottom: 8px;">
                <div class="logo" v-if="selectSpec.url">
                    <img :src="selectSpec.url" alt="logo" width="100%">
                    <div class="upload-operate flex-center">
                        <div class="upload-operate-text">
                            <Upload id="-2" src="product" @success="onSpecLogoUpload">
                                <span class="flex-center">更换</span>
                            </Upload>
                        </div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="upload-operate-text flex-center cursor" @click="deleteSpecImg(index)">删除
                        </div>
                    </div>
                </div>
                <Upload id="-1" src="product" @success="onSpecLogoUpload" v-else>
                    <div class="upload-box flex-center">
                        <i class="el-icon-plus"></i>
                        <span class="text">点击上传</span>
                    </div>
                </Upload>
            </div>
            <span>
                建议图片尺寸为240*240像素，且大小&lt;300kb,支持jpg、png格式，前端会根据用户点击的商品属性展示对应属性图片。
            </span>
            <div style="display: flex;justify-content: center;margin-top: 32px;">
                <el-button type="primary" @click="confirmSpecImg">确定</el-button>
                <el-button @click="closeSpecImg">取消</el-button>
            </div>
         </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Upload from './upload'

    export default {
        name: 'ProductPublish',
        components: {
            Upload
        },
        data() {
            return {
                active: 0,
                categoryId: null,
                productId: null,
                brandId: null,
                title: '',
                desc: '',
                marketPrice: '',
                transportFee: 0,
                returnRate: '',
                salePrice: '',
                specList: [
                    {
                        specname: '',
                        list: [{
                            name: '',
                            checked: false,
                            url:'',
                            cover: JSON.stringify({w:1,h:1,url:''}),
                        }]
                    }
                ],
                skulist: [],
                uploadIndex: -1,
                upLoadList: [
                    {
                        id: 'one',
                        logo: {
                            w: 100,
                            url: '',
                            h: 100
                        },
                        url: ''
                    },
                    {
                        id: 'two',
                        logo: {
                            w: 100,
                            url: '',
                            h: 100
                        },
                        url: ''
                    },
                    {
                        id: 'three',
                        logo: {
                            w: 100,
                            url: '',
                            h: 100
                        },
                        url: ''
                    },
                    {
                        id: 'four',
                        logo: {
                            w: 100,
                            url: '',
                            h: 100
                        },
                        url: ''
                    },
                    {
                        id: 'five',
                        logo: {
                            w: 100,
                            url: '',
                            h: 100
                        },
                        url: ''
                    },
                    {
                        id: 'six',
                        logo: {
                            w: 100,
                            url: '',
                            h: 100
                        },
                        url: ''
                    }
                ],
                video: {
                    src: ''
                },
                contentList: [],
                productdesc: '',
                descVisible: false,
                viplevel: '',
                status: 1,
                startTime: '',
                endTime: '',
                batchPrice: '',
                batchStock: '',
                sort: '999999',
                detail: null,
                videoPlay: false,
                isSpecImgDialog: false,
                selectSpec:{url:''}
            }
        },
        computed: {
            // chooseName() {
            //   let name = []
            //   const dealData = (arr) => {
            //     arr.forEach(item => {
            //       if (item.checked) name.push(item.label)
            //       if (item.children) dealData(item.children)
            //     })
            //   }
            //   dealData(this.categoryOptions)
            //   return name.join(' > ')
            // },
            ...mapState([
                'config',
                'brandOptions',
                'categoryOptions'
            ])
        },
        mounted() {
            this.categoryId = this.$route.query.cid * 1
            this.productId = this.$route.query.id * 1
            if (this.productId) this.getDetail()
        },
        methods: {
            async getDetail() {
                const params = {id: this.productId}
                const url = 'admin/product/detail'
                const detail = await this.$https.get(url, {params})
                if (!detail) return
                this.title = detail.product_name
                this.desc = detail.product_desc
                this.categoryId = detail.category_id
                this.brandId = detail.brand_id
                this.marketPrice = this.$np.divide(detail.market_price, 100)
                this.transportFee = this.$np.divide(detail.transport_fee, 100)
                this.viplevel = detail.vip_level
                this.startTime = detail.start_time
                this.endTime = detail.end_time
                this.status = detail.status
                this.returnRate = detail.return_rate/100
                this.sort = detail.sort
                detail.content_list.forEach(item => {
                    const data = JSON.parse(JSON.stringify(item))
                    if (data.media) {
                        let dbmedia = JSON.parse(data.media)
                        dbmedia.url = this.$img(data.media)
                        data.media = dbmedia
                        data.show = false
                    }
                    this.contentList.push(data)
                })
                this.specList = []
                let specList = []
                detail.spec_list.forEach(item => {
                    const m = {
                        id: item.id,
                        specname: item.spec_name,
                        list: []
                    }
                    item.attr_list.forEach(value => {
                        value.cover = value.cover ? value.cover : JSON.stringify({w:1,h:1,url:''})
                        const source = this.$img(value.cover)
                        value.url = source
                        const n = {
                            id: value.id,
                            name: value.attribute_name,
                            cover: value.cover,
                            url: source,
                            checked: true
                        }
                        m.list.push(n)
                    })
                    specList.push(m)
                })
                this.specList = specList
                detail.sku_list.forEach(item => {
                    item.price = this.$np.divide(item.price, 100)
                    this.skulist.push(item)
                })
                if (detail.main_img) {
                    detail.main_img = this.$img(detail.main_img)
                    detail.main_img.forEach((item, index) => {
                        this.upLoadList[index].url = item.url
                        this.upLoadList[index].logo = item
                    })
                }
                if (detail.main_video) {
                    this.video.src = this.$dealUrl(JSON.parse(detail.main_video).src)
                }
                this.detail = detail
                this.computedSalePrice()
            },
            toAnchor(active) {
                this.active = active
                let anchor = ''
                switch (active) {
                    case 0:
                        anchor = "#basic"
                        break
                    case 1:
                        anchor = "#sku"
                        break
                    case 2:
                        anchor = "#textimg"
                        break
                    case 3:
                        anchor = "#textimginfo"
                        break
                    case 4:
                        anchor = "#serve"
                        break
                    default:
                        break
                }
                document.querySelector(anchor).scrollIntoView(true)
            },
            addSpec() {
                const item = {
                    specname: '',
                    list: [{
                        name: '',
                        url: '',
                        cover: JSON.stringify({w:1,h:1,url:''}),
                        checked: false
                    }]
                }
                this.specList.push(item)
                this.setSkuList()
            },
            deleteGuige(index) {
                this.specList.splice(index, 1)
                this.setSkuList()
            },
            addAttributes(index) {
                const item = {
                    name: '',
                    url: '',
                    cover: JSON.stringify({w:1,h:1,url:''}),
                    checked: false
                }
                this.specList[index].list.push(item)
            },
            deleteSpec(sindex, aindex) {
                this.specList[sindex].list.splice(aindex, 1)
                this.setSkuList()
            },
            deleteSku(value, index) {
                this.skulist.splice(index, 1)
            },
            computedSalePrice() {
                let price = 999999999999
                this.skulist.forEach(item => {
                    if (item.price && item.price * 1 <= price) price = item.price
                })
                this.salePrice = price
            },
            logoClick(idx) {
                this.uploadIndex = idx
            },
            onLogoUpload(url) {
                this.upLoadList[this.uploadIndex].url = url
            },
            onSpecLogoUpload(url) {
                this.selectSpec.url = url
            },
            deleteSpecImg() {
                this.selectSpec.url = ''
            },
            editImg(index, row) {
              row.url = this.$img(row.cover)
              this.selectSpec = row
              this.isSpecImgDialog = true
            },
            confirmSpecImg(){
                this.selectSpec.cover = JSON.stringify({w:1,h:1,url:this.selectSpec.url})
                this.isSpecImgDialog = false
            },
            deleteImg(index) {
                this.upLoadList[index].url = ''
            },
            deleteVedio() {
                this.video.src = ''
            },
            onVideoUpload(url) {
                this.video.src = url
            },
            onPhoneImgUpload(url) {
                if (url) {
                    const data = {
                        is_media_below: 0,
                        content: '',
                        media: {
                            w: 100,
                            h: 100,
                            url
                        },
                        show: false
                    }
                    this.contentList.push(data)
                }
            },
            onPhoneAddText() {
                // const item = this.contentList.find(item => item.content)
                // this.productdesc = item.content
                this.descVisible = true
            },
            confirmDesc() {
                const data = {
                    is_media_below: 0,
                    content: this.productdesc,
                    show: false
                }
                this.contentList.push(data)
                this.descVisible = false
            },
            deleteTextImg(idx) {
                this.contentList.splice(idx, 1)
            },
            descClose() {
                this.productdesc = ''
            },
            closeSpecImg() {
              this.isSpecImgDialog = false 
              this.selectSpec.url = this.$img(this.selectSpec.cover)
            },
            async submit() {
                const spec_list = []
                this.specList.forEach(item => {
                    const m = {
                        spec_name: item.specname,
                        attr_list: []
                    }
                    if (item.id) m.id = item.id
                    item.list.forEach(s => {
                        const n = {
                            attribute_name: s.name,
                            cover: s.cover
                        }
                        if (s.id) n.id = s.id
                        m.attr_list.push(n)
                    })
                    spec_list.push(m)
                })
                const sku_list = this.skulist.map(item => {
                    const j = JSON.parse(JSON.stringify(item))
                    j.price = this.$np.times(j.price, 100)
                    return j
                })
                let main_img = []
                this.upLoadList.forEach(item => {
                    item.logo.url = item.url
                    if (item.logo.url) main_img.push(item.logo)
                })
                let main_video = this.video.src ? this.video : ''
                if (main_video) main_video = JSON.stringify(main_video)
                const content_list = JSON.parse(JSON.stringify(this.contentList))
                content_list.forEach(item => {
                    delete item.show
                    if (item.media) item.media = JSON.stringify(item.media)
                })
                const params = {
                    product_name: this.title,
                    product_desc: this.desc,
                    brand_id: this.brandId,
                    market_price: this.$np.times(this.marketPrice, 100),
                    sale_price: '',
                    transport_fee: this.$np.times(this.transportFee, 100),
                    main_video,
                    main_img: main_img.length !== 0 ? JSON.stringify(main_img) : '',
                    vip_level: this.viplevel,
                    start_time: this.startTime,
                    end_time: this.endTime,
                    status: this.status,
                    spec_list,
                    sku_list,
                    content_list,
                    sort: this.sort,
                    is_sticky: this.detail ? this.detail.is_sticky : 0,
                    id: this.productId ? this.productId : '',
                    return_rate: this.returnRate*100
                }
                if (Array.isArray(this.categoryId) && this.categoryId.length === 3) {
                    params.category_id = this.categoryId[2]
                } else {
                    params.category_id = this.categoryId
                }
                const url = 'admin/product/edit'
                const data = await this.$https.post(url, params)
                if (data) {
                    this.$message.success(this.categoryId ? '编辑成功' : '添加成功')
                    this.$router.push('/product/list')
                }
            },
            cancel() {
                this.$router.push('/product/list')
            },
            changeCheck(item) {
                if (item.checked && !item.name) {
                    this.$message.error('请填写属性')
                    item.checked = false
                    return
                }
                let flag = 0
                this.specList.forEach(spec => {
                    spec.list.forEach(value => {
                        if (item.name === value.name) {
                            flag++
                        }
                    })
                })
                if (flag === 2) {
                    this.$message.error('不能填写相同属性')
                    item.checked = false
                    return
                }
                this.setSkuList()
            },
            setSkuList() {
                this.batchStock = ''
                this.batchPrice = ''
                let arr = []
                this.specList[0].list.forEach(item => {
                    const m = {
                        attribute_name_one: item.name,
                        attribute_name_two: null,
                        price: '',
                        stock: ''
                    }
                    if (this.specList.length === 2) {
                        this.specList[1].list.forEach(value => {
                            if (item.checked) {
                                m.attribute_name_two = value.name
                                if (value.checked) arr.push(JSON.parse(JSON.stringify(m)))
                            }
                        })
                    } else {
                        if (item.checked) arr.push(JSON.parse(JSON.stringify(m)))
                    }
                })
                const anarr = arr.map(item => {
                    this.skulist.forEach(sku => {
                        if (sku.attribute_name_one === item.attribute_name_one && sku.attribute_name_two === item.attribute_name_two) {
                            item = Object.assign({}, item, sku)
                        }
                    })
                    return item
                })
                if (this.detail) {
                    this.skulist = anarr.map(item => {
                        this.detail.sku_list.forEach(sku => {
                            if (sku.attribute_name_one === item.attribute_name_one && sku.attribute_name_two === item.attribute_name_two) {
                                item = Object.assign({}, item, sku)
                            }
                        })
                        return item
                    })
                } else {
                    this.skulist = anarr
                }
                this.computedSalePrice()
            },
            toDemo() {
                this.$alert('<img src="https://ddimage.topboom0912.com/admin_static_img/demo/product_sku.png" alt="demo" width="599px">', '', {
                    dangerouslyUseHTMLString: true,
                    customClass: 'tb-demo-dialog'
                })
            },
            batchOperate() {
                this.skulist.forEach(item => {
                    if (this.batchPrice) item.price = this.batchPrice
                    if (this.batchStock) item.stock = this.batchStock
                })
                this.batchPrice = ''
                this.batchStock = ''
            }
        }
    }
</script>

<style lang="scss">
    .spec-dialog .el-dialog__header {
        border-bottom: 1px solid #E9E9E9;
    }
    
    .spec-dialog .el-dialog__body {
        padding: 24px;
    }
</style>

<style scoped lang="scss">
    .fix-head {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 99;
    }

    .help {
        width: 970px;
        padding: 9px 16px;
        margin-bottom: 16px;
        color: rgba(0, 0, 0, 0.65);
        box-sizing: border-box;
        background-color: #E6F7FF;
        border: 1px solid #BAE7FF;
        border-radius: 4px;

        .align-center {
            align-items: center;
        }

        .el-icon-info {
            color: #1890FF;
            margin-right: 8px;
        }

        .active {
            color: #1890FF;
        }
    }

    .spec {
        .upload {
            display: flex;
            flex-direction: column;
            line-height: 1;
        
            &-box {
                width: 104px;
                height: 104px;
                flex-direction: column;
                border-radius: 4px;
                background: rgba(0, 0, 0, 0.02);
                border: 1px solid rgba(0, 0, 0, 0.15);
                cursor: pointer;
        
                .text {
                    padding-top: 8px;
                    color: rgba(0, 0, 0, 0.65);
                }
            }
        }
        .logo {
            position: relative;
            width: 104px;
            height: 104px;
            overflow: hidden;
            cursor: pointer;
                
            .upload-operate {
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.45);
                
                &-text {
                    flex: 1;
                }
            }
        }
    }

    .item {
        margin: 24px 24px 0;
        background-color: #fff;

        .head {
            padding: 16px 32px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid #E9E9E9;
            box-sizing: border-box;
        }

        .main {
            padding: 0 32px;

            .show-spec {
                font-size: 16px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.85);
            }

            .tips {
                color: rgba(0, 0, 0, 0.45);
            }

            .see-demo {
                padding-left: 16px;
                color: #1890FF;
                font-weight: 600;
                cursor: pointer;
            }

            .label {
                color: rgba(0, 0, 0, 0.85);
            }
        }

        .basic {
            &-item {
                padding-top: 32px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);

                &:last-child {
                    padding-bottom: 32px;
                }

                .special + .special {
                    margin-left: 48px;
                }
            }
        }

        .sku {
            .add {
                padding: 24px 0 16px;
            }

            &-name {
                color: rgba(0, 0, 0, 0.65);
                align-items: center;
            }

            &-table {
                margin-top: 16px;
            }

            .add-item {
                display: inline-block;
                padding: 12px 0 32px 24px;
                color: #1890FF;
                cursor: pointer;

                .icon {
                    margin-right: 4px;
                }

                .tb-icon-delete {
                    width: 14px;
                    height: 14px;
                    vertical-align: middle;
                }
            }
        }

        .textimg {
            height: 728px;
            padding: 24px 0 32px;
            box-sizing: border-box;

            &-left {
                flex-direction: column;
                padding: 0 45px 0 32px;
                border-right: 1px solid #E8E8E8;

                .upload-content {
                    padding: 8px 0;

                    .logoupload {
                        position: relative;
                        width: 104px;

                        .upload-box, .logo {
                            height: 104px;
                        }

                        .first {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            font-size: 12px;
                            color: #fff;
                            background-color: rgba(0, 0, 0, 0.45);
                            z-index: 9;
                        }
                    }

                    .logoupload + .logoupload {
                        margin-left: 8px;
                    }
                }

                .video {
                    padding: 8px 0;
                    width: 260px;

                    .upload-box, .logo {
                        height: 260px;
                    }
                }

                .upload {
                    display: flex;
                    flex-direction: column;
                    line-height: 1;

                    &-box {
                        width: 100%;
                        flex-direction: column;
                        border-radius: 4px;
                        border: 1px dashed rgba(0, 0, 0, 0.15);
                        cursor: pointer;

                        .text {
                            padding-top: 8px;
                            color: rgba(0, 0, 0, 0.65);
                        }
                    }

                    .logo {
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        cursor: pointer;

                        .upload-operate {
                            display: flex;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            font-size: 12px;
                            color: #fff;
                            background-color: rgba(0, 0, 0, 0.45);

                            &-text {
                                flex: 1;
                            }
                        }
                    }
                }
            }

            &-right {
                padding-left: 48px;

                .title {
                    margin: 0;
                    padding-bottom: 16px;
                    font-size: 16px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.85);
                }
            }
        }

        .show {
            margin: 24px 0;
            height: 600px;
            background-color: rgba(0, 0, 0, 0.65);

            .phone {
                display: flex;
                flex-direction: column;
                width: 345px;
                height: 100%;
                margin: 0 auto;
                background-color: #fff;

                &-head {
                    display: block;
                    padding: 20px 0 20px 12px;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: 600;
                }

                &-content {
                    flex: 1;
                    flex-direction: column;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .phone-content-box {
                        position: relative;
                        font-size: 0;

                        .phone-delete-btn {
                            position: absolute;
                            top: 12px;
                            right: 8px;
                            padding: 2px 12px;
                            font-size: 12px;
                            color: #000;
                            border-radius: 4px;
                            border: 1px solid #979797;
                            background-color: #fff;
                            cursor: pointer;
                        }
                    }

                    .phone-text {
                        font-size: 14px;
                        padding: 0 12px;
                    }

                    .phone-img {
                        width: 100%;
                    }
                }

                &-bottom {
                    width: 100%;
                    height: 64px;
                    background-color: #fff;

                    .bottom-btn {
                        flex: 1;
                        flex-direction: column;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.85);
                        cursor: pointer;

                        span {
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .serve {
            padding-bottom: 32px;

            &-item {
                padding-top: 32px;

                .label {
                    display: block;
                    padding-bottom: 8px;
                }

                .time {
                    display: inline-flex;
                    color: rgba(0, 0, 0, 0.85);
                }

                .time + .time {
                    margin-left: 48px;
                }
            }
        }
    }
</style>
