<template>
  <div>
    <Upload :id="id" :src="src" @success="onLogoUpload">
      <slot></slot>
    </Upload>
  </div>
</template>

<script>
import Upload from '@/components/Upload'

export default {
  name: 'ForUpload',
  components: {
    Upload
  },
  props: {
    id: String,
    src: String
  },
  methods: {
    onLogoUpload(url) {
      this.$emit('success', url)
    }
  }
}
</script>
